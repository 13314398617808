@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bevan:ital@0;1&display=swap');

.product-container {
  font-family: 'Poppins', sans-serif;
}

.product-title {
  font-size: 100px;
  font-family: "Bevan", serif;
  font-weight: 400;
  font-style: normal;
  color: white;
  text-shadow: 
    -2px -2px 0 #000,  
    2px -2px 0 #000,
    -2px 2px 0 #000,
    2px 2px 0 #000,
    3px 3px 0 #000,
    4px 4px 0 #000,
    5px 5px 0 #000;
}

.product-title-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.product-title-item {
  text-align: center;
  position: relative;
  display: inline-block;
  font-weight: 700 !important;
}

.strain-price-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 5px;
}

.center-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.product-price-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.product-price-item {
  font-size: 1.25em;
  color: white;
  font-weight: 800;
  text-align: center;
  margin: 0;
}

.product-sale-tag {
  position: absolute;
  left: calc(100% + 10px);
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: auto;
}

.product-card {
  margin: 20px;
  border: none;
  background-color: black !important;
  color: white;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  /* min-height: 500px; */
  display: flex;
  flex-direction: column;
}

.product-card:hover {
  transform: scale(1.05);
}

.product-image-container {
  position: relative;
  cursor: pointer;
  flex-grow: 1;
}

.product-img-top {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out;
}

.product-description {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  overflow-y: auto;
}

.product-lineage,
.product-description-text,
.product-dosage {
  margin-bottom: 15px;
  font-size: 1em;
  width: 100%;
  font-weight: 600;
}

.product-description strong,
.product-dosage strong {
  color: #FFD700;
  display: block;
  margin-bottom: 8px;
  font-size: 1.5em;
}

.product-lineage span,
.product-description-text span,
.product-dosage span {
  font-style: italic;
  display: block;
  font-weight: 700;
}

.product-description-text span,
.product-dosage span {
  width: calc(100% - 40px);
  margin: 0 auto;
  padding: 0 20px;
}

.product-card.show-description .product-img-top {
  opacity: 0;
}

.product-card.show-description .product-description {
  opacity: 1;
}

.brand-filter {
  margin-bottom: 20px;
}

.brand-filter select {
  max-width: 200px;
  margin: 0 auto;
  background-color: #333;
  color: white;
  border: 1px solid #555;
}

.b2g1-icon {
  position: absolute;
  bottom: -115px;
  left: 8px;
  width: 120px;
  height: auto;
  z-index: 10;
}
@media(max-width:1345px) {
  .b2g1-icon{
    width: 100px;
  }
}

@media (max-width:1272px) and (min-width:992px) {
  .b2g1-icon {
    width: 80px;
  }
}

@media(max-width:385px) {
  .b2g1-icon{
    width: 80px;
  }
}
@media(max-width:330px) {
  .b2g1-icon{
    width: 70px;
    left:0px
  }
}
/* }
@media(max-width:790px) {
  .b2g1-icon{
    width: 100px;
  }
} */


@media (max-width: 767px) {
  .product-title {
    font-size: 50px;
  }
  .product-card:hover {
    transform: none;
  }
}

@media (max-width: 435px) {
  .product-title {
    font-size: 30px;
  }
}

@media (min-width: 1500px) {
  .product-container {
    padding: 0 5%;
  }
}

@media (min-width: 1900px) {
  .product-container {
    padding: 0 10%;
  }
}

@media (min-width: 2400px) {
  .product-container {
    padding: 0 15%;
  }
}

@media (max-width: 1700px) {
  .product-lineage span,
  .product-description-text span,
  .product-dosage span {
    font-size: 0.9vw;
  }
}

@media (max-width: 991px) {
  .product-lineage span,
  .product-description-text span,
  .product-dosage span {
    font-size: 1.45vw;
  }
}

@media (max-width: 767px) {
  .product-lineage span,
  .product-description-text span,
  .product-dosage span {
    font-size: 3.2vw;
  }
  .product-description strong,
  .product-dosage strong {
    font-size: 1.7em;
  }
}

@media (max-width: 420px) {
  .product-lineage span,
  .product-description-text span,
  .product-dosage span {
    font-size: 3vw;
  }
  .product-description strong,
  .product-dosage strong {
    font-size: 1.2em;
  }
}

@media (max-width: 335px) {
  .product-lineage span,
  .product-description-text span,
  .product-dosage span {
    font-size: 2.75vw;
  }
  .product-description strong,
  .product-dosage strong {
    font-size: 1.2em;
  }
  .product-title-item {
    font-size: 1.250em !important;
  }
}