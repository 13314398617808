.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #04fcfc 0%, #fc0471 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .loading-content {
    text-align: center;
  }
  
  .loading-image {
    width: 200px;
    height: auto;
    animation: pulse 2s ease-in-out infinite;
  }
  
  .loading-text {
    margin-top: 20px;
    font-family: 'Arial', sans-serif;
    font-size: 24px;
    color: #ffffff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    animation: fadeInOut 2s ease-in-out infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes fadeInOut {
    0%, 100% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
  }
  .maintenance-message {
    margin-top: 20px;
    font-family: 'Arial', sans-serif;
    font-size: 18px;
    color: #ffffff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    animation: fadeInOut 2s ease-in-out infinite;
  }
  
  .contact-info {
    margin-top: 20px;
  }
  
  .contact-email {
    color: #ffffff;
    text-decoration: none;
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .contact-email:hover {
    text-decoration: underline;
  }