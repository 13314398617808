@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');

.nav-title {
  font-size: 30px;
}

@media (max-width: 323px) {
  .nav-title {
    font-size: 7.25vw;
  }
}

.navbar-toggler.active,
.navbar-toggler-icon.active {
  outline: none;
  box-shadow: none;
  color: white;
}

.ms-auto {
  color: white;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 700 !important;
  margin-right: 50px;
}

@media (max-width: 991px) {
  .navbar-nav,
  .navbar-nav.ms-auto-collapsed {
    margin-left: 30px !important;
  }
}

.navbar-blur {
  position: relative;
  z-index: 1;
  transition: top 0.3s;
}

.navbar-blur.show {
  top: 0;
}

.navbar-blur.hide {
  top: -250px; /* Adjust this value if needed */
}

.navbar-blur::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  background-color: rgba(255, 255, 255, 0.02); /* Optional: adjust the opacity */
  z-index: -1;
}

.nav-link:hover {
  color: pink !important;
}

@media (min-width: 992px) {
  .nav-link {
    font-size: 20px !important;
  }
}

@media (min-width: 1200px) {
  .nav-link {
    font-size: 25px !important;
  }
}

/* New styles for the navbar content and phone number */
.navbar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.phone-number {
  margin-left: 20px;
}

.phone-number a {
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 20px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.phone-number a:hover {
  color: pink;
}

@media (max-width: 991px) {
  .navbar-content {
    flex-wrap: wrap;
    justify-content: center;
  }

  .navbar-brand {
    order: 1;
    margin-right: 0;
  }

  .phone-number {
    order: 3;
    width: 100%;
    text-align: center;
    margin: 10px 0;
  }

  .navbar-toggler {@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');

    .nav-title {
      font-size: 30px;
    }
    
    @media (max-width: 323px) {
      .nav-title {
        font-size: 7.25vw;
      }
    }
    
    .navbar-toggler.active,
    .navbar-toggler-icon.active {
      outline: none;
      box-shadow: none;
      color: white;
    }
    
    .ms-auto {
      color: white;
      font-family: 'Poppins', sans-serif !important;
      font-weight: 700 !important;
      margin-right: 50px;
    }
    
    @media (max-width: 991px) {
      .navbar-nav,
      .navbar-nav.ms-auto-collapsed {
        margin-left: 30px !important;
      }
    }
    
    .navbar-blur {
      position: relative;
      z-index: 1;
      transition: top 0.3s;
    }
    
    .navbar-blur.show {
      top: 0;
    }
    
    .navbar-blur.hide {
      top: -285px; /* Adjust this value if needed */
    }
    
    .navbar-blur::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px);
      background-color: rgba(255, 255, 255, 0.02); /* Optional: adjust the opacity */
      z-index: -1;
    }
    
    .nav-link:hover {
      color: pink !important;
    }
    
    @media (min-width: 992px) {
      .nav-link {
        font-size: 20px !important;
      }
    }
    
    @media (min-width: 1200px) {
      .nav-link {
        font-size: 25px !important;
      }
    }
    
    /* New styles for the navbar brand container and phone number */
    .navbar-brand-container {
      display: flex;
      align-items: center;
    }
    
    .phone-number {
      margin-left: 20px;
    }
    
    .phone-number a {
      color: white;
      font-family: 'Poppins', sans-serif;
      font-weight: 700;
      font-size: 20px;
      text-decoration: none;
      transition: color 0.3s ease;
    }
    
    .phone-number a:hover {
      color: pink;
    }
    
    @media (max-width: 991px) {
      .navbar-brand-container {
        flex: 1;
        justify-content: space-between;
      }
      
      .navbar-toggler {
        order: 2;
      }
    }
    
    @media (max-width: 700px) {
      .navbar-brand-container {
        flex-direction: column;
        align-items: center;
      }
    
      .phone-number {
        margin-left: 0;
        margin-top: 10px;
      }
    
      .phone-number a {
        font-size: 16px;
      }
    }
    
    @media (max-width: 500px) {
      .phone-number a {
        font-size: 14px;
      }
    }
    
    @media (max-width: 400px) {
      .phone-number a {
        font-size: 12px;
      }
    }
    order: 2;
  }
}